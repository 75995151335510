import { toaster } from 'tcomponents/organisms/NotificationWrapper';
import {
  fetchTapConfigAPI, searchUsersAPI, updateTapConfigAPI,
} from '../api/TapConfigurations.api';
import { CONSTANTS } from '../constants/Constants';
const { ACTIONS } = CONSTANTS;

/**
 * @description Function to fetch config data.
 */
export const getTapConfig = baseUrl => (
  (dispatch) => {
    dispatch({ type: ACTIONS.SET_LOADER, payload: { getTapConfig: true } });
    return fetchTapConfigAPI(baseUrl).then((response) => {
      dispatch({ type: ACTIONS.SET_LOADER, payload: { getTapConfig: false } });
      dispatch({ type: ACTIONS.FETCH_TAP_CONFIG, payload: response.data });
    }).catch((error) => {
      dispatch({ type: ACTIONS.SET_LOADER, payload: { getTapConfig: false } });
      dispatch({ type: ACTIONS.FETCH_LISTING_ERROR, payload: error });
      toaster('error', __('Error getting, Tap config details.'));
    });
  }
);

/**
 * @description Function to fetch config data.
 */
export const updateTapConfig = (baseUrl, data) => (
  (dispatch) => {
    dispatch({ type: ACTIONS.SET_LOADER, payload: { updateTapConfig: true } });
    return updateTapConfigAPI(baseUrl, data).then(() => {
      dispatch({ type: ACTIONS.SET_LOADER, payload: { updateTapConfig: false } });
      dispatch(getTapConfig(baseUrl));
    }).catch((error) => {
      dispatch({ type: ACTIONS.SET_LOADER, payload: { updateTapConfig: false } });
      dispatch({ type: ACTIONS.FETCH_LISTING_ERROR, payload: error });
      toaster('error', __('Error updating, Tap config details.'));
    });
  }
);

/**
 * @description Function to fetch all users in tekion.
 */
export const searchUsers = (baseUrl, query) => (
  (dispatch) => {
    dispatch({ type: ACTIONS.SET_LOADER, payload: { autoComplete: true } });
    return searchUsersAPI(baseUrl, query).then((response) => {
      dispatch({ type: ACTIONS.SET_LOADER, payload: { autoComplete: false } });
      dispatch({ type: ACTIONS.FETCH_TAP_USERS, payload: response.data });
    }).catch((error) => {
      dispatch({ type: ACTIONS.SET_LOADER, payload: { autoComplete: false } });
      dispatch({ type: ACTIONS.FETCH_LISTING_ERROR, payload: error });
      toaster('error', __('Error getting, Tap users details.'));
    });
  }
);
