// Function to sort dates field
export const sortedDates = (dates, key) => {
  const sorttedDates = dates.sort((a, b) => (a[key] > b[key]) ? 1 : -1);
  return sorttedDates;
};

export const clearMigratedTokens = () => {
  const keys = [
    'access_token',
    'uname',
    'rememberMe',
    'email',
    'roleId',
    'idToken',
    'access_token_expire_date',
  ];
  keys.forEach(key => localStorage.removeItem(key));
};

