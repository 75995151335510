import { toaster } from 'tcomponents/organisms/NotificationWrapper';
import { getValueFromEnv } from '@tekion/tap-components/utils/commonHelper';
import { fetchPreferencesApi, updateNotificationApi, updateSummaryApi, updateSummaryTMApi } from '../api/TaskManagement';
import { CONSTANTS } from '../constants/Constants';
const { ACTIONS } = CONSTANTS;
const BASE_URL_2 = getValueFromEnv('BASE_URL_2');
const BASE_URL = getValueFromEnv('BASE_URL');

export const fetchGetPreferences = baseUrl => (
  (dispatch) => {
    const url = baseUrl === 'https://us-west1-tapapi.tekioncloud.com' ? 'https://us-west1-tapapi-01.tekioncloud.com' : baseUrl;
    dispatch({ type: ACTIONS.FETCH_GET_USER_PERFERENCE_SUCCESS, payload: null });
    return fetchPreferencesApi(url).then((response) => {
      dispatch({ type: ACTIONS.FETCH_GET_USER_PERFERENCE_SUCCESS, payload: response });
    }).catch((error) => {
      dispatch({ type: ACTIONS.FETCH_GET_USER_PERFERENCE_ERROR, payload: error });
    });
  }
);

export const updateNotification = (baseUrl, data) => (
  (dispatch) => {
    return new Promise((resolve, reject) => {
      updateNotificationApi(baseUrl, data).then(() => {
        dispatch(fetchGetPreferences(baseUrl));
        resolve();
        toaster('success', __('Preference details updated successfully'));
      }).catch((error) => {
        dispatch({ type: ACTIONS.FETCH_GET_USER_PERFERENCE_ERROR, payload: error });
        toaster('error', __('Failed to update Preference details'));
      });
    });
  }
);

export const updateSummary = (baseUrl, data) => (dispatch, getState) => {
  const { task = {} } = getState();
  const { perference = {} } = task;
  const { userPreference } = perference;
  const url = BASE_URL_2;
  return updateSummaryApi(url, data).then((response = {}) => {
    const updatedUserPreference = {
      ...userPreference, ...data,
    };
    const updatedPreference = { ...perference, userPreference: { ...updatedUserPreference } };
    dispatch({ type: ACTIONS.FETCH_GET_USER_PERFERENCE_SUCCESS, payload: updatedPreference });
    const { msg = __('Changes are saved') } = response;
    toaster('success', msg);
  }).catch((error) => {
    dispatch({ type: ACTIONS.FETCH_GET_USER_PERFERENCE_ERROR, payload: error });
    toaster('error', __('Failed to update'));
  });
};

export const updateTMSummary = (baseUrl, data) => (dispatch, getState) => {
  const { task = {} } = getState();
  const { perference = {} } = task;
  const { userPreference } = perference;
  const url = baseUrl === 'https://us-west1-tapapi.tekioncloud.com' ? 'https://us-west1-tapapi-01.tekioncloud.com' : baseUrl;
  return updateSummaryTMApi(url, data).then((response = {}) => {
    const updatedUserPreference = {
      ...userPreference, ...data,
    };
    const updatedPreference = { ...perference, userPreference: { ...updatedUserPreference } };
    dispatch({ type: ACTIONS.FETCH_GET_USER_PERFERENCE_SUCCESS, payload: updatedPreference });
    const { msg = __('Changes are saved') } = response;
    toaster('success', msg);
  }).catch((error) => {
    dispatch({ type: ACTIONS.FETCH_GET_USER_PERFERENCE_ERROR, payload: error });
    toaster('error', __('Failed to update'));
  });
};
