import { stringInterpolate } from '@tekion/tekion-base/formatters/string';
import { getLocalTimeZone } from '@tekion/tekion-base/utils/dateUtils';
import { doGet, doPost, doPut } from '../services/http';
import {
  getUserPreference, postUpdateNotification, updateUserPreference, postUpdateSummary,
} from './urls';

export const fetchPreferencesApi = url => doGet(`${url}${getUserPreference}`);
export const updateNotificationApi = (url, data) => doPost(`${url}${postUpdateNotification}`, data);
export const updateSummaryApi = (url, data) => doPut(`${url}${updateUserPreference}`, data, { timeZone: getLocalTimeZone() });
export const updateSummaryTMApi = (url, data) => doPut(`${url}${stringInterpolate(postUpdateSummary, {})}`, data, { timeZone: getLocalTimeZone() });
